import React, {createContext, useContext, useEffect, useReducer} from 'react';

const initialState = {
  leftSidebarOpened: false,
  rightSidebarOpened: false
};

const SidebarStateContext = createContext();
const SidebarDispatchContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'leftSidebarOpen':
      return { ...state, leftSidebarOpened: action.open };
    case 'rightSidebarOpen':
      return { ...state, rightSidebarOpened: action.open };
    case 'updateState':
      return { ...state, ...action.state };
  }
}

export default function SidebarProvider({children, defaultState}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({type: "updateState", state: defaultState});
  }, [defaultState.leftSidebarOpened, defaultState.rightSidebarOpened]);
  return (
    <SidebarStateContext.Provider value={state}>
      <SidebarDispatchContext.Provider value={dispatch}>
        { children }
      </SidebarDispatchContext.Provider>
    </SidebarStateContext.Provider>
  );
}

function useSidebarState() {
  return useContext(SidebarStateContext);
}

function useSidebarDispatch() {
  return useContext(SidebarDispatchContext);
}

function withSidebarDispatch(Component) {
  return props => (
    <SidebarDispatchContext.Consumer>
      { context => <Component sidebarDispatch={context} {...props}/> }
    </SidebarDispatchContext.Consumer>
  );
}

export {useSidebarState, useSidebarDispatch, withSidebarDispatch};