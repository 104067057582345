import React, {Component} from 'react';
import './App.sass';
import Header from './Header';
import Main from "./Main";
import SidebarProvider from "../store/SidebarProvider";
import StreamInfoContext from "../store/StreamInfoContext";
import ChatProvider from "../store/ChatProvider";

class App extends Component {
  state = {
    stream_info: {
      "title": "",
      "header_title": "",
      "yt_url": "",
      "from_start": false,
      "left_sidebar_opened": false,
      "right_sidebar_opened": false,
      "show_program": "Show program >",
      "hide_program": "< Hide program",
      "show_exhibition": "< Show exhibition",
      "hide_exhibition": "Hide exhibition >",
      "directory_button": "",
      "directory_href": "javascript:history.back()",
      "support_button": "",
      "chat_app_id": null,
      "chat_settings": null
    },
    chatHandlers: null,
    firstFetch: true
  };
  componentDidMount() {
    this.fetchLiveStream();
  }

  fetchLiveStream = () => {
    fetch("LiveStream.json", {cache: 'no-cache'})
      .then((response) => response.json())
      .then(this.dataReceived)
      .then(this.afterFetch)
      .then(this.timeOut)
      .then(this.fetchLiveStream);
  };

  timeOut = () => new Promise(resolve => {
    window.setTimeout(resolve, 3000)
  });

  dataReceived = (data) => new Promise(resolve => {
    const { stream_info } = this.state;
    if (data['yt_url'] === stream_info['yt_url']) resolve();

    this.setState(
      {
        stream_info: { ...stream_info, ...data }
      },
      resolve
    );
  });

  afterFetch = () => {
    const { stream_info, firstFetch } = this.state;
    if (!firstFetch) return;

    document.title = stream_info["title"];
    this.initChat();
    this.setState({ firstFetch: false });
  };

  initChat = () => {
    const { stream_info } = this.state;
    if (stream_info["chat_app_id"] !== null && stream_info["chat_settings"] !== null) {
      const handlers = window.sChat.init(stream_info["chat_app_id"], stream_info["chat_settings"]);
      this.setState({ chatHandlers: handlers });
    }
  };

  render() {
    const { stream_info, chatHandlers } = this.state;
    const sidebar_defaults = {
      leftSidebarOpened: stream_info["left_sidebar_opened"],
      rightSidebarOpened: stream_info["right_sidebar_opened"],
    };
    return (
      <div className="app">
        <SidebarProvider defaultState={sidebar_defaults}>
          <ChatProvider chatHandlers={chatHandlers}>
            <StreamInfoContext.Provider value={stream_info}>
              <Header/>
              <Main/>
            </StreamInfoContext.Provider>
          </ChatProvider>
        </SidebarProvider>
      </div>
    );
  }
}

export default App;
