import React from 'react';
import './Sidebar.sass';

function Sidebar(props) {
  return (
    <aside
      className="sidebar"
      style={ {width: props.open ? props.width : 0} }
    >
      { props.children }
    </aside>
  );
}

export default Sidebar;