import React, {useState} from "react";
import YTPlayer from "./YTPlayer";
import Sidebar from "./Sidebar";
import Content from "./Content";
import EventSchedule from "./EventSchedule";
import ExhibitionList from "./ExhibitionList";
import './Main.sass';
import {useSidebarState} from "../store/SidebarProvider";
import {useStreamInfo} from "../store/StreamInfoContext";

function Main() {
  const sidebarState = useSidebarState();
  const streamInfo = useStreamInfo();
  const [time, setTime] = useState();

  return (
    <main className="main">
      <Sidebar open={sidebarState.leftSidebarOpened} width="350px">
        <EventSchedule onSelect={item => item.time && setTime(item.time)}/>
      </Sidebar>
      <Content>
        <YTPlayer url={streamInfo.yt_url} fromStart={streamInfo.from_start} time={time}/>
      </Content>
      <Sidebar open={sidebarState.rightSidebarOpened} width="350px">
        <ExhibitionList/>
      </Sidebar>
    </main>
  );
}

export default Main;